import { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SnoozeRoundedIcon from '@material-ui/icons/SnoozeRounded';
import Button from '@material-ui/core/Button';
import CloseIcon from '../../common/icons/CloseIcon';
import { useAppDispatch, useAppSelector } from '../../../store';
import { pendTaskAsync } from '../../../store/slices/taskboardSlice';
import { openModal } from '../../../store/slices/uiSlice';
import EditIcon from '../../common/icons/EditIcon';
import DeleteIcon from '../../common/icons/DeleteIcon';

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const Actions = styled.nav`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const IconButton = styled.button`
  border-radius: 50%;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease;

  :hover {
    background: #63616b;
  }
`;

const RequestId = styled.p`
  color: #e0e0e0;
`;

const EditButtonWrapper = styled.div`
  position: relative;
  margin-right: 8px;
`;
const Menu = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 8px;
  padding: 10px;
`;

const StyledIconButton = styled(IconButton)<{ isActive?: boolean }>`
  border-radius: 6px;
  background-color: ${({ isActive }) => isActive && '#24242d'};

  :hover {
    background-color: #24242d;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  width: 100%;
  justify-content: flex-start;
  color: #fff;
`;

function TaskPopupHeader({ task, handleClose }) {
  const wrapperRef = useRef(null);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const auth = useAppSelector((state) => state.auth);

  const toogleMenu = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const clickListener = useCallback(
    (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        toogleMenu();
      }
    },
    [toogleMenu]
  );

  const handlePending = async () => {
    await dispatch(pendTaskAsync({ taskId: task._id }));
    setOpen(false);
  };

  const openEditTaskModal = async () => {
    dispatch(openModal({ type: 'edit-task', props: { task } }));
  };

  const openDeleteModal = async () => {
    dispatch(openModal({ type: 'delete-task', props: { taskId: task._id } }));
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('click', clickListener);
    }

    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, [open, clickListener]);

  return (
    <Wrapper>
      <RequestId>ID: {task.requestId}</RequestId>
      <Actions>
        {auth.isAdmin && (
          <EditButtonWrapper ref={wrapperRef}>
            {(task.status <= 1 || task.isPending || task.status == 5) && (
              <StyledIconButton isActive={open} onClick={toogleMenu}>
                <MoreHorizIcon style={{ color: 'rgb(197, 209, 219)' }} />
              </StyledIconButton>
            )}
            {open && (
              <Menu>
                <StyledButton
                  size="large"
                  color="secondary"
                  variant="text"
                  onClick={openEditTaskModal}
                  startIcon={<EditIcon />}
                >
                  Edit task
                </StyledButton>
                {!task.isPending && (
                  <StyledButton
                    size="large"
                    fullWidth
                    color="secondary"
                    variant="text"
                    onClick={handlePending}
                    startIcon={<SnoozeRoundedIcon />}
                  >
                    start pending
                  </StyledButton>
                )}
                <StyledButton
                  size="large"
                  color="secondary"
                  variant="text"
                  onClick={openDeleteModal}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </StyledButton>
              </Menu>
            )}
          </EditButtonWrapper>
        )}
        <StyledIconButton onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>
      </Actions>
    </Wrapper>
  );
}

export default TaskPopupHeader;
